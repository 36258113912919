import { Viewer } from '../user';

const OBSERVER_MARGIN = `${window.outerHeight}px`;
const SAFE_REQUEST_TIMEOUT = 6000;
const REFRESH_TIMEOUT = 30000;
const INTERSTITIAL = `interstitial`;

window.googletag = window.googletag || { cmd: [] };

class MonetizationManager {
  /**
   * Create a monetization manager.
   * It based on using of google publisher tag ({@link https://developers.google.com/publisher-tag/guides/get-started|GPT}).
   *
   * @param {Array} refreshingSlots – Slot for refreshing.
   */
  constructor(refreshingSlots) {
    this.gpt = window.googletag;

    /** @type {BaseAdapter[]} */
    this.adapters = [];

    /** @type {AdSlot[]} */
    this.adSlots = [];
    this.isInitialFetch = true;
    this._observer = null;
    this._refreshTimeouts = {};
    this._refreshingSlots = refreshingSlots;
    this._onSlotRenderedCallback = () => {};

    this._ppid = Viewer.id;
  }

  /**
   * Targeting setter. Combine global page targeting with device screen size.
   *
   * @param {Object} targeting Global page targeting.
   */
  set targeting(targeting) {
    this.gpt.cmd.push(() => {
      this._targeting = {
        ...targeting,
        screen: `${window.screen.width}x${window.screen.height}`,
      };

      Object.entries(this._targeting).forEach(([key, value]) => {
        this.gpt.pubads().setTargeting(key, value instanceof Array ? value : value.toString());
      });

      this.gpt.pubads().set(`page_url`, window.location.href);
    });
  }

  /**
   * Callback when slot rendered.
   *
   * @param {Function} callback Callback function.
   */
  set onSlotRendered(callback) {
    this._onSlotRenderedCallback = callback;
  }

  addSlots(adSlots = [], refreshedSlotPaths = []) {
    this.adSlots = [...this.adSlots, ...adSlots];
    this._refreshingSlots = Array.from(new Set([...this._refreshingSlots, ...refreshedSlotPaths]));
    this.registerGPTSlots(adSlots);
    this._observeAdSlots(adSlots);

    this.adapters?.forEach((adapter) => {
      adapter.registerSlots(
        adSlots.filter(
          (adSlot) => !adSlot.isOutOfPage || adSlot.config.name.includes(INTERSTITIAL),
        ),
      );
      adapter.fetch(adSlots.filter((adSlot) => !adSlot.isLazy));
    });
  }

  /**
   * Add new header bidding adapter.
   *
   * @param {BaseAdapter} adapter Header bidding adapter.
   * @returns {void}
   */
  addAdapter(adapter) {
    this.adapters.push(adapter);
    adapter.onLoad = this._onAdapterFetch.bind(this);
    adapter.init();
  }

  /**
   * Register all slots for GPT.
   *
   * @param {AdSlot[]} adSlots – Array of ad slots for registration.
   * @returns {void}
   */
  registerGPTSlots(adSlots) {
    if (!adSlots.length) {
      return;
    }

    this.gpt.cmd.push(() => {
      adSlots.forEach((adSlot) => {
        if (Math.random() * 100 > adSlot.frequency) {
          return;
        }
        this._registerGPTSlot(adSlot);
      });
    });
  }

  /**
   * Send request to ad server and register header bidding adapters.
   *
   * @returns {void}
   */
  enableGPT() {
    this.gpt.cmd.push(() => {
      this.gpt.pubads().enableSingleRequest();
      this.gpt.pubads().setCentering(true);
      this.gpt.pubads().disableInitialLoad();
      this.gpt.pubads().setPublisherProvidedId(this._ppid);
      this.gpt.enableServices();
    });

    this.gpt.cmd.push(() => {
      this.gpt.pubads().addEventListener(`slotRenderEnded`, this._onSlotRendered.bind(this));
      this._addRefreshing();
      this._safeRequestTimeout = setTimeout(() => {
        if (this.isInitialFetch === true) {
          console.log(`_safeRequestTimeout`);
          this.isInitialFetch = false;
          this._initAdSlotsObserver();
          this._fetchNotLazyAds(this.adSlots);
        }
      }, SAFE_REQUEST_TIMEOUT);
    });
  }

  /**
   * Publisher Provided ID setter.
   *
   * @param {string | null} ppid – unique string ID or null.
   */

  set ppid(ppid) {
    if (ppid) {
      this._ppid = ppid;
    }
  }

  /**
   * Register ad slot for GPT.
   *
   * @param {AdSlot} adSlot Ad slot for registration.
   * @private
   *
   * @returns {void}
   */
  _registerGPTSlot(adSlot) {
    adSlot.googleSlot = adSlot.isOutOfPage
      ? this.gpt.defineOutOfPageSlot(adSlot.path, this.gpt.enums.OutOfPageFormat.INTERSTITIAL)
      : this.gpt.defineSlot(adSlot.path, adSlot.size, adSlot.id);

    if (adSlot.googleSlot) {
      adSlot.googleSlot?.addService(this.gpt.pubads());
      this.gpt.display(adSlot.googleSlot);
    }
  }

  /**
   * Create observer for lazy loading ad slots.
   *
   * @private
   * @returns {void}
   */
  _initAdSlotsObserver() {
    this.gpt.cmd.push(() => {
      this._observer = new IntersectionObserver(
        (items) => {
          const slotsForRefresh = [];

          items.forEach((item) => {
            if (item.isIntersecting) {
              this._observer.unobserve(item.target);
              slotsForRefresh.push(this._getSlotById(item.target.id));
            }
          });

          if (slotsForRefresh.length) {
            this.adapters?.forEach((adapter) => adapter.fetch(slotsForRefresh));
          }
        },
        { rootMargin: OBSERVER_MARGIN },
      );

      this._observeAdSlots(this.adSlots);
    });
  }

  _observeAdSlots(adSlots) {
    adSlots
      .filter((slot) => slot.isLazy)
      .forEach((slot) => {
        this._observer?.observe(slot.node);
      });
  }

  /**
   * Callback when adapters fetch bids.
   *
   * @param {AdSlot[]} adSlots – Ad slots to fetch
   * @param {string} adapter – name of adapter
   *
   * @callback
   * @private
   * @returns {void}
   */
  _onAdapterFetch(adSlots, adapter) {
    adSlots.forEach((slot) => slot.addAdapterLoaded(adapter));
    const isReady = adSlots.every((slot) => slot.countAdapterLoaded === this.adapters.length);

    this.gpt.cmd.push(() => {
      if (isReady) {
        if (this.isInitialFetch === true) {
          this.isInitialFetch = false;
          clearTimeout(this._safeRequestTimeout);
          this._initAdSlotsObserver();
          this._fetchNotLazyAds(adSlots);
        } else {
          console.log(`_onAdapterFetch refresh`, this.isInitialFetch);
          this.gpt.pubads().refresh(adSlots.map((slot) => slot.googleSlot));
        }

        adSlots.forEach((slot) => slot.clearAdaptersLoaded());
      }
    });
  }

  /**
   * Get ad slot by id.
   *
   * @param {string} id Id of ad slot.
   * @returns {AdSlot} Ad slot object.
   * @private
   */
  _getSlotById(id) {
    return this.adSlots.find((adSlot) => adSlot.id === id);
  }

  /**
   * Get ad slot by gpt slot.
   *
   * @param {Object} googleSlot GPT slot object.
   * @returns {AdSlot} Ad slot object.
   * @private
   */
  _getSlotByGptSlot(googleSlot) {
    return this.adSlots.find((adSlot) => adSlot.googleSlot === googleSlot);
  }

  /**
   *
   * @private
   * @returns {void}
   */
  _addRefreshing() {
    if (this._refreshingSlots.length) {
      this.gpt.pubads().addEventListener(`slotVisibilityChanged`, ({ slot, inViewPercentage }) => {
        const adSlot = this._getSlotByGptSlot(slot);

        if (this._shouldSlotRefresh(adSlot)) {
          if (inViewPercentage === 0) {
            clearTimeout(this._refreshTimeouts[adSlot?.id]);
            this._refreshTimeouts[adSlot?.id] = null;
          }

          if (inViewPercentage > 50 && !this._refreshTimeouts[adSlot?.id]) {
            this._refreshTimeouts[adSlot?.id] = this._setSlotRefresh(adSlot);
          }
        }
      });
    }
  }

  /**
   * Add timer for slot refreshing.
   *
   * @param {Object} slot GPT slot.
   * @returns {number} Timer id.
   * @private
   */
  _setSlotRefresh(slot) {
    return setTimeout(() => {
      this.adapters.forEach((adapter) => adapter.fetch([slot]));
      this._refreshTimeouts[slot?.id] = null;
    }, REFRESH_TIMEOUT);
  }

  /**
   * Handler for slot render end event.
   *
   * @param {Object} event GPT event.
   * @private
   * @returns {void}
   */
  _onSlotRendered(event) {
    if (event.isEmpty) {
      return;
    }

    const adSlot = this._getSlotByGptSlot(event.slot);

    if (/branding/.test(adSlot?.path)) {
      document.body.classList.add(`branded`);
    } else if (/anchor/.test(adSlot?.path)) {
      const parent = document.querySelector(`[data-slot="${adSlot?.path}"]`).parentNode;
      parent.classList.remove(`visually-hidden`);
    }

    this._onSlotRenderedCallback(adSlot?.path);
  }

  /**
   * Fetch only ad slots without lazy loading.
   *
   * @param {AdSlot[]} adSlots – Ad slots to fetch
   *
   * @private
   * @returns {void}
   */
  _fetchNotLazyAds(adSlots) {
    this.gpt.cmd.push(() => {
      const notLazySlots = adSlots.filter((slot) => !slot.isLazy).map((slot) => slot.googleSlot);
      this.gpt.pubads().refresh(notLazySlots);
    });
  }

  /**
   * Check if slot should refresh.
   *
   * @param {AdSlot} adSlot – Ad slot object.
   * @private
   * @returns {boolean} – Is slot should refresh.
   */
  _shouldSlotRefresh(adSlot) {
    return this._refreshingSlots.some((refreshKey) =>
      adSlot.path.toLowerCase().includes(refreshKey),
    );
  }
}

export default MonetizationManager;
