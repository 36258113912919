import addScript from '../services/script-loader.js';

const defaultYtParams = {
  layout: 'default',
  theme: 'default',
  count: 'hidden'
};

function isParamsValid($mainContainer, ytparams) {
  if (!$mainContainer || !$mainContainer.nodeType) {
    throw Error('Incorrect first param $mainContainer (should be a DOM elem)');
  }

  if (!ytparams || !ytparams.channelid) {
    throw Error('Incorrect second param ytparams. YouTube channelId is required for subscribe button');
  }

  return true;
}

function renderYtSubscribe($mainContainer, ytparams, activeClass) {
  /* Array.prototype.slice.call needed for old browsers with bad polyfill */
  const $wrappers = Array.prototype.slice.call($mainContainer.querySelectorAll('.js-ytsubscribe'));
  $wrappers.forEach($wrapperItem => {
    const $container = $wrapperItem.querySelector('.js-ytsubscribe-container');
    if ($container) {
      const $ytsubscribe = document.createElement('div');
      $container.appendChild($ytsubscribe);
      if (activeClass) {
        $wrapperItem.classList.add(activeClass);
      } else {
        $wrapperItem.style.display = 'block';
      }
      window.gapi.ytsubscribe.render($ytsubscribe, {...defaultYtParams, ...ytparams});
    }
  });
}

export function renderYoutubeWidget($mainContainer, ytparams) {
  if (isParamsValid($mainContainer, ytparams)) {
    renderYtSubscribe($mainContainer, ytparams);
  }
}

export function renderYoutubeWidgetWithScript($mainContainer, ytparams, activeClass) {
  if (isParamsValid($mainContainer, ytparams)) {
    addScript('https://apis.google.com/js/platform.js', () => {
      renderYtSubscribe($mainContainer, ytparams, activeClass);
    });
  }
}
