class ArticleChangeObserver {
  constructor(onChange = () => {}) {
    this.observer = this._createObserver(onChange);
  }

  observe($element) {
    this.observer.observe($element);
  }

  unobserve($element) {
    this.observer.unobserve($element);
  }

  _createObserver(onChange) {
    return new IntersectionObserver(
      (changes) => {
        changes
          .filter((change) => change.isIntersecting)
          .forEach((change) =>
            onChange(change.target.closest(`[data-article-id]`).dataset.articleId),
          );
      },
      { rootMargin: `-59% 0% -40% 0%`, threshold: 0 },
    );
  }
}

export default ArticleChangeObserver;
