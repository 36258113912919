import { renderFragment } from '../services/render-fragment';
import templater from '../services/templater';
import { LABELS, sendMeasurement, TYPES } from './analytics';
import { hideGlobalMask, showGlobalMask } from './popup-mask';
import { hideHeader, isHidden, showHeader } from './sticky-header';

const SELECTORS = {
  imageSource: `article-image__picture`,
  container: `article-image`,
  template: `js-gallery-popup`,
  preview: `gallery-popup__image`,
  previewWrapper: `gallery-popup__wrapper`,
  isOpen: `gallery-popup--open`,
  openButton: `article-image__button`,
  closeButton: `gallery-popup__button`,
  isLoading: `gallery-popup--is-loading`,
  hasError: `gallery-popup--has-error`,
  status: `gallery-popup__status`,
};

const $template = document.querySelector(`#${SELECTORS.template}`);
const $popup = renderFragment(templater($template?.innerHTML, {}));
const $wrapper = $popup?.querySelector(`.${SELECTORS.previewWrapper}`);
const $closeButton = $popup?.querySelector(`.${SELECTORS.closeButton}`);
const $status = $popup?.querySelector(`.${SELECTORS.status}`);

$popup && document.body.appendChild($popup);

const handleClose = () => {
  $popup?.classList.remove(SELECTORS.isOpen);
  $wrapper?.replaceChildren();
  hideGlobalMask();
  showHeader();
};

const handleExpand = (image) => () => {
  $popup?.classList.add(SELECTORS.isLoading);
  $popup?.classList.remove(SELECTORS.hasError);

  const newImage = new Image();
  newImage.classList.add(SELECTORS.preview);
  newImage.alt = image.alt;
  newImage.width = image.width;
  newImage.height = image.height;

  $popup && $popup.classList.add(SELECTORS.isOpen);
  showGlobalMask();
  hideHeader();

  if ($status) {
    $status.textContent = `Loading...`;
  }

  newImage.addEventListener(`load`, () => {
    $popup?.classList.remove(SELECTORS.isLoading);
    $wrapper?.appendChild(newImage);
    sendMeasurement(LABELS.articleImage, TYPES.click);

    if (!isHidden()) {
      sendMeasurement(LABELS.articleImage, TYPES.headerOverlapping);
    }
  });

  newImage.addEventListener(`error`, () => {
    $popup?.classList.remove(SELECTORS.isLoading);
    $popup?.classList.add(SELECTORS.hasError);

    if ($status) {
      $status.textContent = `Can't load image at the moment`;
    }

    setTimeout(handleClose, 3000);
  });

  newImage.src = image.src;
};

$popup?.addEventListener(`click`, (event) => {
  if ($popup === event.target) {
    handleClose();
  }
});

$closeButton?.addEventListener(`click`, handleClose);

export const initGallery = ($root = document) => {
  [...$root.querySelectorAll(`.${SELECTORS.container}`)].forEach(($container) => {
    const $image = $container.querySelector(`.${SELECTORS.imageSource}`);
    const $button = $container.querySelector(`.${SELECTORS.openButton}`);

    if ($image) {
      $image.addEventListener(`click`, handleExpand($image));
    }

    if ($button) {
      $button.addEventListener(`click`, handleExpand($image));
    }
  });
};
