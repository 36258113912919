import { isDesktop } from '../services/viewport';

const FB_MESSENGER_BUTTON = `facebook-messenger`;
const SHARING_BUTTON = `.js-social-sharing-button`;

export const addEventsOnShareButtons = ($root = document) => {
  const $sharingButtons = $root.querySelectorAll(SHARING_BUTTON);

  $sharingButtons.forEach(($button) => {
    $button.addEventListener(`click`, (event) => {
      const buttonType = event.currentTarget.getAttribute(`data-type`);

      window.gtag?.(`event`, `share_button_click`, {
        type: buttonType,
      });

      if (isDesktop() && buttonType === FB_MESSENGER_BUTTON) {
        event.preventDefault();
        window.FB.ui && window.FB.ui({ method: `send`, link: window.location.href });
      }
    });
  });
};
