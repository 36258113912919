import BaseAdapter from './base-adapter';
import { isAYMEnabled } from '../../experiments/assertive-experiment';

if (!isAYMEnabled()) {
  window.apstag = window.apstag || {
    init(...args) {
      this._Q.push([`i`, args]);
    },
    fetchBids(...args) {
      this._Q.push([`f`, args]);
    },
    setDisplayBids() {},
    targetingKeys() {
      return [];
    },
    _Q: [],
  };
}
class AmazonAdapter extends BaseAdapter {
  constructor(id) {
    super();

    this.id = id;
  }

  init = () => {
    window.apstag.init({
      pubID: this.id,
      adServer: `googletag`,
    });
  };

  registerSlots = () => null;

  fetch = (adSlots) => {
    const filteredSlots = adSlots.filter((slot) => !slot.isOutOfPage);

    if (filteredSlots.length) {
      window.apstag.fetchBids(
        {
          slots: filteredSlots.map(({ id, path, size }) => ({
            slotID: id,
            slotName: path,
            sizes: size.filter((currentSize) => currentSize !== `fluid`),
          })),
        },
        () => {
          window.apstag.setDisplayBids();
          this.onLoad(adSlots, `amazon`);
        },
      );
    }
  };
}

export default AmazonAdapter;
