export const LABELS = {
  articleImage: `article_image`,
};

export const TYPES = {
  click: `click`,
  headerOverlapping: `header_overlapping`,
};

export const sendMeasurement = (label, type) => {
  if (window.gtag === undefined) {
    console.error(
      `There is no gtag in window. Can't send "custom_measurement" event  with ${JSON.stringify({
        label,
        type,
      })} payload`,
    );
    return;
  }

  window.gtag(`event`, `custom_measurement`, {
    label,
    type,
  });
};
