import templater from '../templater';
import { renderFragment } from '../render-fragment';

const articleTemplate = document.querySelector(`#article-template`)?.textContent;
const ARTICLE_BODY_CLASS = `js-article-body`;

class Article {
  constructor(articleData, $rootElement = null) {
    this.$root = $rootElement;
    this.$articleBody = null;
    this.data = articleData;
    this.viewed = false;
    this._url = new URL(this.data.attributes.url);
  }

  /**
   * @returns {number[]} Returns IDs of the article ad slots.
   */
  get adSlotIds() {
    return [...this.$root.querySelectorAll(`[data-slot]`)].map((adSlot) => adSlot.id);
  }

  /**
   * @returns {HTMLElement | null} Returns root element of the article.
   */
  get $body() {
    if (this.$articleBody === null) {
      this.$articleBody = this.$root.querySelector(`.${ARTICLE_BODY_CLASS}`);
    }

    return this.$articleBody;
  }

  /**
   * @returns {string} Returns title of the article.
   */
  get title() {
    return this.data.attributes.title;
  }

  /**
   * @returns {URL} Returns [URL]{@link URL} object of the article url.
   */
  get url() {
    return this._url;
  }

  /**
   * @returns {number} Returns ID of the article.
   */
  get id() {
    return this.data.id;
  }

  /**
   * @returns {Array} Returns authors of the article.
   */
  get author() {
    return this.data.meta?.analytics?.article_authors || [];
  }

  /**
   * @returns {Array} Returns article parent category titles.
   */
  get category() {
    return this.data.meta?.analytics?.article_categories || [];
  }

  /**
   * @returns {Array} Returns article child category titles.
   */
  get subcategory() {
    return this.data.meta?.analytics?.article_subcategories || [];
  }

  /**
   * @returns {Array} Returns article parent category slugs.
   */
  get categorySlugs() {
    return this.data.meta?.analytics?.article_category_slugs || [];
  }

  /**
   * @returns {Array} Returns article child category slugs.
   */
  get subcategorySlugs() {
    return this.data.meta?.analytics?.article_subcategory_slugs || [];
  }

  /**
   * @returns {Array} Returns tags of the article.
   */
  get tag() {
    return this.data.meta?.analytics?.tag || [];
  }

  /**
   * @returns {Array} Returns list of the articles from ReadAlso service.
   */
  get readAlso() {
    return this.data?.relationships?.read_also || [];
  }

  get hasReadAlso() {
    return this.data?.relationships?.read_also.length > 0;
  }

  sendAnalytics() {
    if (this.data?.meta?.analytics) {
      const {
        page_title,
        page_url,
        page_url_canonical,
        page_type,
        page_language,
        article_authors,
        tag,
        article_categories,
        article_subcategories,
        article_publication_date,
        article_type,
      } = this.data.meta.analytics;

      window._io_config?.[`0.2.0`].push({
        page_title,
        page_url,
        page_url_canonical,
        page_type,
        page_language,
        article_authors,
        tag,
        article_categories,
        article_subcategories,
        article_publication_date,
        article_type,
      });
      window.gtag?.(`event`, `page_view`, {
        send_to: window.Site.ga4Tracker,
        page_path: this.url.pathname,
        is_evergreen: this.data.attributes.is_evergreen,
      });
    }
  }

  render(trendingArticles = ``) {
    if (this.$root === null) {
      this.$root = renderFragment(
        templater(articleTemplate, {
          jsonLd: this.data.meta?.widgets?.json_ld?.content,
          socialLinks: this.data.meta?.widgets?.social_inks?.content,
          content: this.data.attributes.content,
          mainHeadline: this.title,
          articleId: this.id,
          trending: trendingArticles,
          ...this._getAdSlots(),
        }),
      );
    }
  }

  _getAdSlots() {
    const slotsHtml = Object.values(window.adSharedConfigs)
      .filter((config) => config.gptConfig.div)
      .reduce((slots, config) => {
        if (config.gptConfig.div) {
          slots[config.gptConfig.name] = config.gptConfig.div;
        }

        return slots;
      }, {});

    return this.data.attributes.without_ads ? {} : slotsHtml;
  }
}

export default Article;
