const TABLE_OF_CONTENTS_LIST = `.table-of-contents__list-wrapper`;
const TABLE_OF_CONTENTS_BTN = `.table-of-contents__btn`;

export const enableTableOfContentsButton = ($root = document) => {
  const $tableOfContentsList = $root.querySelector(TABLE_OF_CONTENTS_LIST);
  const $tableOfContentsButton = $root.querySelector(TABLE_OF_CONTENTS_BTN);

  if ($tableOfContentsButton) {
    $tableOfContentsButton.addEventListener(`click`, (event) => {
      $tableOfContentsList.classList.toggle(`table-of-contents__list--view-more`);
      $tableOfContentsButton.textContent === `+ View more`
        ? ($tableOfContentsButton.textContent = `- View less`)
        : ($tableOfContentsButton.textContent = `+ View more`);
    });
  }
};
