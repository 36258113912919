import { isMobile } from '../../services/viewport';
import { CLASS, CONTENT_BASED, USER_BASED } from './constants';
import RecommendationPopup from './recommendation-popup';
import { getArticle } from '../../services/api';

// Temporary disabled check on email subscription cookie exist while disable Sendios subscription
const hasEmailSubscriptionPopupCookies = () => true;

const getPopupData = async (article, type) => {
  if (type === CONTENT_BASED) {
    const articleData = await getArticle(article.id)
      .then(({ data }) => data)
      .catch(() => console.error(`Cant get data for content based recommendation popup.`));

    return {
      href: articleData.attributes.url,
      headline: articleData.attributes.main_headline,
    };
  }

  if (type === USER_BASED) {
    return {
      href: article.attributes.href,
      headline: article.attributes.main_headline,
    };
  }

  return null;
};

export const initRecommendationPopup = async (data, type) => {
  if (isMobile() && data) {
    const $mainContent = document.querySelector(CLASS.main);
    const $article = document.querySelector(CLASS.article);
    const $recommendationPopup = $mainContent.querySelector(CLASS.popupRootElement);

    if (hasEmailSubscriptionPopupCookies()) {
      const popupData = await getPopupData(data, type);

      if (popupData) {
        const popup = new RecommendationPopup($recommendationPopup, popupData);
        popup.init();

        const observerMargin = $article.offsetHeight * 0.55;

        const recommendationPopupObserver = new IntersectionObserver(
          (changes) => {
            if (!changes[0].isIntersecting) {
              popup.show();
              recommendationPopupObserver.unobserve($article);
            }
          },
          { rootMargin: `-${observerMargin}px 0px ${observerMargin}px 0px` },
        );

        recommendationPopupObserver.observe($article);
      }
    }
  }
};
