/**
 * Classes used for element selection.
 *
 * @enum {string}
 */
export const CLASS = {
  popupRootElement: `.js-recommendation-popup`,
  article: `.js-article`,
  headline: `.js-headline`,
  body: `.js-body`,
  close: `.js-close`,
  main: `.js-main`,
};

/**
 * Type of recommended article.
 *
 * @type {string}
 */
export const CONTENT_BASED = `content_based`;
export const USER_BASED = `user_based`;
