import { get, post } from '../services/api';
import templater from '../services/templater';
import { renderFragment } from '../services/render-fragment';

export const SELECTORS = {
  container: `#recommendation-widget`,
  template: `#js-template-recommendation`,
  itemTemplate: `#js-template-recommendation-item`,
  placeholder: `.recommendation__item`,
};

const MAX_SIDEBAR_ITEMS = 5;

const $template = document.querySelector(SELECTORS.template);
const $itemTemplate = document.querySelector(SELECTORS.itemTemplate);

export const ENTITY_TYPES = {
  article: `article_id`,
  category: `category_id`,
};

export const render = (data, root = document) => {
  if (!$template || !$itemTemplate) {
    throw new ReferenceError(`RECOMMENDATION: There is no template for widget.`);
  }

  const $container = root.querySelector(SELECTORS.container);

  if (!data || data.length === 0) {
    $container.innerHTML = ``;
    return;
  }

  const $placeholders = $container.querySelectorAll(SELECTORS.placeholder);
  let index = 0;

  for (const $placeholder of $placeholders) {
    const articleData = data[index];
    index = index + 1;

    if (!articleData) {
      $placeholder.remove();
      continue;
    }

    const $article = renderFragment(templater($itemTemplate.innerHTML, articleData.attributes));
    $placeholder.replaceWith($article);
  }
};

export const renderSidebar = (data = [], root) => {
  render(data.slice(0, MAX_SIDEBAR_ITEMS), root);
};

export const getEntityRecommendation = (
  visitorId,
  entityId = null,
  entityType = ENTITY_TYPES.article,
  limit = 3,
) =>
  get(`/ajax/recommendation/`, {
    visitor_id: visitorId,
    [entityType]: entityId,
    limit,
  })
    .then(({ data }) => {
      if (!data || !data.length) {
        throw new Error(`RECOMMENDATION: There's no results from API.`);
      }

      return data;
    })
    .catch((error) => console.error(error.message));

export const getRecommendation = (visitorId, limit) =>
  getEntityRecommendation(visitorId, null, null, limit);

export const addVisit = (visitorId, entityId, entityType = ENTITY_TYPES.article) => {
  post(`/ajax/visit/`, { visitor_id: visitorId, [entityType]: entityId });
};
