/**
 * Transform object with query params to a string.
 *
 * @param {Object} params – Object of query params.
 * @param {string} nesting – Nesting prefix in object tree.
 *
 * @returns {string} – Query string based on passed params object.
 */
const stringify = (params, nesting = ``) =>
  Object.entries(params)
    .map(([key, value]) => {
      // Skip parameter with nullish value
      if (value === null || typeof value === `undefined`) {
        return ``;
      }

      let output;
      const prefix = nesting ? `${nesting}[${key}]` : key;

      if (Array.isArray(value)) {
        output = value.length
          ? value.map((item) => [`${prefix}[]`, item].join(`=`)).join(`&`)
          : prefix;
      } else if (typeof value === `object`) {
        output = stringify(value, prefix);
      } else {
        output = [prefix, value].join(`=`);
      }

      return output;
    })
    .join(`&`);

/**
 * Send GET request using fetch API.
 *
 * @param {string} url – URL for getting resource.
 * @param {Object} params – Query params for request.
 *
 * @returns {Promise<any>} – Received data.
 */
export const get = (url, params = {}) =>
  fetch(`${window.location.origin}${url}?${stringify(params)}`, {
    method: `GET`,
  })
    .then((response) => response.json())
    .catch((response) => response.status);

export const post = (url, body) =>
  fetch(`${window.location.origin}${url}`, {
    method: `POST`,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': `application/json`,
    },
  });

/**
 * Retrieve article data by article id.
 *
 * @param {number} id – Article ID.
 * @param {Object} params –  Query params for request.
 *
 * @returns {Promise<*>} – Object with articles data.
 */
export const getArticle = (id, params = {}) => get(`/ajax/article/${id}/`, params);

/**
 * Retrieve articles data collection.
 * @param {number} limit – Articles per request.
 * @param {number} offset – Collection offset.
 *
 * @returns {Promise<*>} – Array of articles data.
 */
export const getTrending = (limit = 2, offset = 0) =>
  get(`/ajax/articles/`, {
    filter: {
      type: `trending`,
    },
    page: {
      limit,
      offset,
    },
  });
