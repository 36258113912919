const MAX_PRIORITY = 10;

class UniquePrioritizedQueue {
  constructor() {
    this.queue = [];
    this.seen = new Set();
  }

  push(value, priority) {
    if (priority > MAX_PRIORITY) {
      throw new Error(`Priority must be max - ${MAX_PRIORITY}`);
    }

    if (this.seen.has(value)) {
      return;
    }

    if (!this.queue[priority]) {
      this.queue[priority] = [];
    }

    this.queue[priority].push(value);
    this.seen.add(value);
  }

  pull() {
    if (!this.queue.length) {
      return null;
    }

    const value = this.queue[this.queue.length - 1].pop();

    this.#recalculate();

    return value;
  }

  get hasAny() {
    return this.queue.length > 0;
  }

  #recalculate() {
    for (let priority = this.queue.length - 1; priority >= 0; priority--) {
      if (this.queue[priority] && this.queue[priority].length) {
        break;
      }

      this.queue.pop();
    }
  }
}

export default UniquePrioritizedQueue;
