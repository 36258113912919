import InfiniteLoader from '../infinite-loader';
import { getArticle, getTrending } from '../api';
import { isMobile } from '../viewport';
import UniquePrioritizedQueue from '../unique-prioritized-queue';

class ArticleInfiniteLoader {
  constructor($root) {
    this.$root = $root;
    this.infiniteLoader = this._createInfiniteLoader();
    this.articlesQueue = new UniquePrioritizedQueue();
  }

  set onLoad(callback) {
    this.infiniteLoader.onLoad = callback;
  }

  getLatestArticlesIds(articles) {
    return articles
      .sort((prev, next) => prev.id - next.id)
      .slice(-CONSTANTS.AMOUNT_OF_ARTICLES)
      .map((article) => article.id);
  }

  addArticles(data, priority) {
    const articlesIds = this.getLatestArticlesIds(data);
    articlesIds.forEach((id) => this.articlesQueue.push(id, priority));
  }

  _createInfiniteLoader() {
    const loader = new InfiniteLoader(
      this.$root,
      (offset) =>
        Promise.all([
          getArticle(this.articlesQueue.pull()).catch(console.error),
          getTrending(CONSTANTS.TRENDING_LIMIT, offset * CONSTANTS.TRENDING_LIMIT).catch(
            console.error,
          ),
        ]),
      isMobile() ? 4 : 2,
    );

    loader.canLoad = () => this.articlesQueue.hasAny;
    return loader;
  }
}

export default ArticleInfiniteLoader;
