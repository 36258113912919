export const renderFragment = (htmlString = ``) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, `text/html`);

  if (doc.body.firstChild) {
    return doc.body.firstElementChild;
  }

  return document.createElement(`div`);
};
