import { CLASS } from './constants';

class RecommendationPopup {
  /**
   * Create instance of recommendation popup.
   *
   * @param {Element} rootElement Root element of the recommendation popup.
   * @param {object} popupData  Data for recommendation popup.
   */
  constructor(rootElement, popupData) {
    this.$root = rootElement;
    this.$popupData = popupData;
    this.$closeButton = rootElement.querySelector(CLASS.close);
    this.$headlineElement = rootElement.querySelector(CLASS.headline);
    this.$bodyElement = rootElement.querySelector(CLASS.body);
    this._onClose = () => {};

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this._closeHandler = this._closeHandler.bind(this);
    this.$closeButton.addEventListener(`click`, this._closeHandler);
  }

  /**
   * Set callback on popup close.
   *
   * @param {Function} callback Close callback.
   */
  set onClose(callback) {
    this._onClose = callback;
  }

  /**
   * Show popup.
   *
   * @return {void}
   */
  show() {
    this.$root.classList.add(`recommendation-popup__show`);
  }

  /**
   * Hide popup.
   *
   * @return {void}
   */
  hide() {
    this.$root.classList.remove(`recommendation-popup__show`);
  }

  /**
   * Init popup.
   *
   * @return {void}
   */
  init() {
    this.$headlineElement.innerHTML = this.$popupData.headline;
    this.$bodyElement.href = this.$popupData.href;
  }

  /**
   * Close popup.
   *
   * @private
   *
   * @return {void}
   */
  _closeHandler() {
    this.hide();
    this._onClose();
  }
}

export default RecommendationPopup;
