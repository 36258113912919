import addScript from '../../services/script-loader';
import {
  FACEBOOK_EMBED_TYPE,
  INFOGRAM_EMBED_TYPE,
  INSTAGRAM_EMBED_TYPE,
  RIDDLE_EMBED_TYPE,
  TIKTOK_EMBED_TYPE,
  TWITTER_EMBED_TYPE,
} from './config';

export const embedLoaders = {
  [TIKTOK_EMBED_TYPE]: (target) => {
    const creatTiktok = () =>
      window.tiktokEmbed && window.tiktokEmbed.lib.render([target.firstChild]);

    if (!window.tiktokEmbed) {
      addScript(`https://www.tiktok.com/embed.js`, creatTiktok);
    } else {
      creatTiktok();
    }
  },
  [INSTAGRAM_EMBED_TYPE]: (target) => {
    const creatInstagram = () => window.instgrm && window.instgrm.Embeds.process(target);

    if (!window.instgrm) {
      // At that moment instagram script not working if FB sdk was
      // loaded before, this line of code is temporary solution
      window.FB.__buffer = true;

      addScript(`https://www.instagram.com/embed.js`, creatInstagram);
    } else {
      creatInstagram();
    }
  },
  [TWITTER_EMBED_TYPE]: (target) => {
    const createTweet = () => window.twttr && window.twttr.widgets.load(target);

    if (!window.twttr) {
      addScript(`https://platform.twitter.com/widgets.js`, createTweet);
    } else {
      createTweet();
    }
  },
  [FACEBOOK_EMBED_TYPE]: (target) => {
    const createFacebook = () => window.FB && window.FB.XFBML.parse(target);

    if (!window.FB) {
      addScript(`https://connect.facebook.net/en_US/sdk.js`, () => {
        window.fbAsyncInit = () => {
          window.FB.init({
            xfbml: true,
            version: `v15.0`,
          });
          createFacebook();
        };
      });
    } else {
      createFacebook();
    }
  },
  [RIDDLE_EMBED_TYPE]: () => {
    if (!window.riddleAPI) {
      addScript(`https://www.riddle.com/embed/build-embedjs/embedV2.js`);
    }
  },
  [INFOGRAM_EMBED_TYPE]: () => {
    const createInfogram = () => window.InfogramEmbed && window.InfogramEmbed.load();

    if (!window.InfogramEmbed) {
      addScript(`https://e.infogram.com/js/dist/embed.js`, createInfogram);
    } else {
      createInfogram();
    }
  },
};
