import { renderYoutubeWidgetWithScript } from './modules/youtube-subscribe.js';
import { observeLazySources } from './modules/lazy-sources-loader.js';
import { getArticle } from './services/api';
import AdSlot from './modules/monetization/ad-slot';
import { monetizationManager } from './monetization.js';
import ArticleInfiniteLoader from './services/article/article-infinite-loader';
import Article from './services/article/article';
import ArticleChangeObserver from './services/article/article-change-observer';
import { getNestedText } from './services/article/get-nested-text';
import { getPolicyViolatedWords } from './modules/monetization/get-policy-violated-words';
import {
  addVisit,
  getEntityRecommendation,
  ENTITY_TYPES,
  renderSidebar,
} from './modules/recommendation';
import { Viewer } from './modules/user';
import { addEventsOnShareButtons } from './modules/sharing';
import { enableTableOfContentsButton } from './modules/table-of-contents-button';
import { isDesktop } from './services/viewport';
import { initRecommendationPopup } from './modules/recommendation-popup';
import { CONTENT_BASED, USER_BASED } from './modules/recommendation-popup/constants';
import { isPopupExperiment } from './experiments/popup-experiment';
import { initEmbeds } from './modules/embeds';
import { initGallery } from './modules/gallery.js';
import { isAYMEnabled } from './experiments/assertive-experiment';
import { initComments } from './modules/comments';

const registerAdSlots = (article) => {
  if (window.adSharedConfigs) {
    const uniqueConfigs = Object.values(window.adSharedConfigs)
      .map((sharedConfig) => sharedConfig.getUniqueConfig())
      .filter((uniqueConfig) => article.adSlotIds.includes(uniqueConfig.gptConfig.code));

    const slots = uniqueConfigs.map((config) => new AdSlot(config, article.$root));

    const { id: articleID, author, tag, categorySlugs, subcategorySlugs } = article;
    const violated_words = [
      ...new Set([
        ...window.Site.targeting.violated_words,
        ...getPolicyViolatedWords(getNestedText(article.$body)),
      ]),
    ];

    const targeting = Object.assign(window.Site.targeting, {
      articleID,
      author,
      tag,
      category: [...categorySlugs, ...subcategorySlugs],
      violated_words,
    });

    if (!isAYMEnabled()) {
      monetizationManager.targeting = targeting;
      monetizationManager.addSlots(slots);
    }
  }
};

const createArticle = (data, trendingMarkup) => {
  const article = new Article(data);
  article.render(trendingMarkup);
  observeLazySources(article.$root);
  enableTableOfContentsButton(article.$root);
  addEventsOnShareButtons(article.$root);

  return article;
};

/* need for old browsers without defer */
document.addEventListener(
  `DOMContentLoaded`,
  () => {
    if (window.Site.youTubeChannelId) {
      renderYoutubeWidgetWithScript(
        document,
        { channelid: window.Site.youTubeChannelId },
        `c-article__ytsubscribe--show`,
      );
    }

    const $infiniteContainer = document.querySelector(`.js-infinite-container`);
    const articleInfiniteLoader = new ArticleInfiniteLoader($infiniteContainer);

    enableTableOfContentsButton();
    addEventsOnShareButtons();

    getArticle(window.Site.entity_id)
      .then(({ data: initialArticleData }) => {
        const articles = {};

        const initialArticle = new Article(
          initialArticleData,
          document.querySelector(`.js-article`),
        );

        initGallery(initialArticle.$root);
        initComments(initialArticle);

        if (initialArticle.hasReadAlso && FEATURES.INFINITE_SCROLL) {
          articleInfiniteLoader.addArticles(initialArticle.readAlso, 0);
        }

        articles[initialArticle.id] = initialArticle;
        initialArticle.viewed = true;

        if (initialArticle.hasReadAlso && !isPopupExperiment() && FEATURES.RECOMMENDATION_POPUP) {
          initRecommendationPopup(initialArticle.readAlso.at(-1), CONTENT_BASED);
        }

        if ($infiniteContainer) {
          const articleChangeObserver = new ArticleChangeObserver((currentArticleId) => {
            const article = articles[currentArticleId];

            if (article.url.pathname !== window.location.pathname) {
              window.history?.replaceState({}, article.title, article.url.pathname);
              window.document.title = articles[currentArticleId].title;
            }

            if (!article.viewed) {
              article.viewed = true;
              article.sendAnalytics();
            }
          });
          articleChangeObserver.observe(initialArticle.$body);

          articleInfiniteLoader.onLoad = (
            [{ data: articleData }, { data: trendingData }],
            pageNumber,
          ) => {
            const article = createArticle(
              articleData,
              trendingData.reduce((markup, trend) => markup + trend.attributes.content, ``),
            );

            articles[article.id] = article;
            articleChangeObserver.observe(article.$body);

            $infiniteContainer.appendChild(article.$root);
            initGallery(article.$root);

            registerAdSlots(article);

            initEmbeds(article.$root);
            initComments(article);

            if (FEATURES.INFINITE_SCROLL_GA4_TRACKING) {
              window.gtag?.(`event`, `infinite_scroll`, {
                scroll_depth: pageNumber,
              });
            }

            if (FEATURES.RECOMMENDATION) {
              getEntityRecommendation(Viewer.id, article.id, ENTITY_TYPES.article, 10).then(
                (data) => {
                  if (isDesktop()) {
                    renderSidebar(data, article.$root);
                  }
                },
              );
            }
          };
        }
      })
      .catch(console.error);

    if (FEATURES.RECOMMENDATION) {
      getEntityRecommendation(Viewer.id, window.Site.entity_id, ENTITY_TYPES.article, 10).then(
        (data) => {
          if (data && FEATURES.INFINITE_SCROLL && FEATURES.INFINITY_WITH_RECOMMENDATION) {
            articleInfiniteLoader.addArticles(data, 1);
          }

          renderSidebar(data);

          if (data && isPopupExperiment() && FEATURES.RECOMMENDATION_POPUP) {
            initRecommendationPopup(data.at(-1), USER_BASED);
          }
        },
      );

      setTimeout(() => {
        // Send a visit only for users who started reading the article for a while.
        addVisit(Viewer.id, window.Site.entity_id, ENTITY_TYPES.article);
      }, 3000);
    }

    /* initialization of lazy loading */
    observeLazySources();
  },
  false,
);
