import { createLazyLoadingObserver } from '../lazy-loading-observer';
import { EMBED_CLASS } from './config';
import { embedLoaders } from './embed-loaders';

export const initEmbeds = ($root = document) => {
  const embeds = $root.querySelectorAll(EMBED_CLASS);

  const lazyObserver = createLazyLoadingObserver(({ target = {} } = {}) => {
    if (embedLoaders[target.dataset.type]) {
      embedLoaders[target.dataset.type](target);
    }

    lazyObserver.unobserve(target);
  });

  embeds.forEach((embed) => lazyObserver.observe(embed));
};
