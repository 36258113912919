import { getViewportHeight } from './viewport';

const OBSERVE_REFRESH_TIME = 500;

class InfiniteLoader {
  /**
   * Creates instance of observer that observes container for infinite scroll data.
   * It stop loading when there is no data or max request number was exceeded.
   *
   * @param {Element} $container – Container for infinite scroll data.
   * @param {Function} load – Function that invokes when observer triggers.
   * @param {number} rootMarginRatio – Coefficient of the root margin for observer.
   */
  constructor($container, load, rootMarginRatio = 2) {
    this.$container = $container;
    this.load = load;
    this.maxRequestNumber = CONSTANTS.AMOUNT_OF_ARTICLES;
    this.observer = null;
    this.isLoading = false;
    this.rootMarginRatio = rootMarginRatio;

    this._onLoad = () => {};
    this._canLoad = () => {};
    this._requestsNumber = 1;

    this._initObserver();
  }

  /**
   * Sets callback that invokes after data has been loaded.
   *
   * @param {Function} callback – Callback function.
   */
  set onLoad(callback) {
    this._onLoad = callback.bind(this);
  }

  /**
   * Sets callback that check if data can be loaded.
   *
   * @param {Function} callback – Callback function.
   */
  set canLoad(callback) {
    this._canLoad = callback.bind(this);
  }

  /**
   * Inits observer for container and invokes callbacks.
   *
   * @private
   *
   * @returns {void}
   */
  _initObserver() {
    const rootMargin = Math.min(
      getViewportHeight(),
      this.$container.getBoundingClientRect().height,
    );

    const refreshObserve = setInterval(() => {
      this._unobserve();
      this.observer.observe(this.$container);
    }, OBSERVE_REFRESH_TIME);

    this.observer = new IntersectionObserver(
      (changes) => {
        if (!this._isNextRequestAvailable()) {
          this._unobserve();
        }

        if (this._canLoad()) {
          clearInterval(refreshObserve);
        } else {
          return;
        }

        if (!changes[0].isIntersecting && !this.isLoading) {
          this.isLoading = true;

          this.load(this._requestsNumber)
            .then((data) => {
              this._onLoad(data, this._requestsNumber);
              this._requestsNumber = this._requestsNumber + 1;
            })
            .catch((error) => {
              this._unobserve();
              console.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
      {
        rootMargin: `-${this.rootMarginRatio * rootMargin}px 0px ${
          this.rootMarginRatio * rootMargin
        }px 0px`,
      },
    );

    this.observer.observe(this.$container);
  }

  /**
   * Checks if there are some available requests to send based on max request number field.
   *
   * @returns {boolean} – Boolean whether send next request or not.
   * @private
   */
  _isNextRequestAvailable() {
    return this._requestsNumber <= this.maxRequestNumber;
  }

  /**
   * Stops observing container and set fields to default.
   *
   * @returns {void}
   * @private
   */
  _unobserve() {
    this.observer.unobserve(this.$container);
    this.isLoading = false;
  }
}

export default InfiniteLoader;
